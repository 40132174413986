import React from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import TokenInput from 'components/Input/TokenInput';
const SignInUKGReadyApi = ({
  client,
  isLoading,
  onSubmit,
  provider
}: RegisterImplementationProps) => {
  const instructionsLink = 'https://support.tryfinch.com/hc/en-us/articles/26233108662036-UKG-Ready-Add-a-Service-User';
  const instructions: JSX.Element = <p>
      To connect your {provider.displayName} account, you must create a service
      account for <strong>{client.name}</strong>. If you already have a service
      account, you can use that one.
      <br />
      <br />
      Follow{' '}
      <a href={instructionsLink} rel="noopener noreferrer" target="_blank">
        Finch’s instructions
      </a>{' '}
      to complete these steps and then come back here to continue connecting.
    </p>;
  return <SignInBase instructions={instructions} divider onSubmit={onSubmit} fieldNames={['instructionCheck', 'fullDomain', 'username', 'password', 'apiKey', 'clientCode']} render={({
    instructionCheck,
    fullDomain,
    username,
    password,
    apiKey,
    clientCode
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <Input value={fullDomain.value} onChange={event => fullDomain.onChange(event.target.value)} invalid={fullDomain.invalid} label="Web Service URL" id="fullDomain" helperText="Example: https://service.ultipro.com" />
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" id="username" />
              <PasswordInput value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} />
              <TokenInput value={apiKey.value} onChange={event => apiKey.onChange(event.target.value)} invalid={apiKey.invalid} label="API Key" id="apiKey" />
              <Input value={clientCode.value} onChange={event => clientCode.onChange(event.target.value)} invalid={clientCode.invalid} label="Company Short Name" id="clientCode" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !fullDomain.value || !username.value || !password.value || !apiKey.value || !clientCode.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInUKGReadyApi" data-sentry-source-file="SignInUKGReadyApi.tsx" />;
};
export default SignInUKGReadyApi;