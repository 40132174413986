import React, { useState, useRef } from 'react';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import Input from 'components/Input/Input';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import MainContainer from 'components/Container/MainContainer';
import NoticeMessage from '../../Messages/Notice';
import InstructionHeader from '../../../../components/Instruction/InstructionHeader';
import { validateSubdomain } from '../utils';
const {
  protocol,
  host
} = window.location;
const BAMBOOHR_CLIENT_ID = protocol === 'https:' ? 'gh_auto_gen-0b7a2a47db90886a518a0eae3509c73c00b5ebea-06_07_2022_15:45:55' // prod client creds
: 'gh_auto_gen-464b3cf7a1c35de268f2f6abbd1f5cf7017f7e20-08_19_2022_07:32:11'; // dev client creds
const BAMBOOHR_REDIRECT_URI = `${protocol}//${host}/auth/bamboo_hr`;
const RegisterBambooHRApi = ({
  provider,
  handleClick,
  onSubmit,
  onMockOAuth,
  sandboxMode,
  setError
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [loading, setLoading] = useState(false);
  const externalWindowRef = useRef<Window | null>(null);
  const handleSubmit = async (fieldToValueMap: Record<string, string>) => {
    const didMockOAuth = onMockOAuth();
    if (didMockOAuth) {
      return;
    }
    const {
      subdomain
    } = fieldToValueMap;
    setLoading(true);
    if (!validateSubdomain(subdomain)) {
      setError({
        message: 'Invalid subdomain. Subdomain should contain no spaces, symbols, or http prefixes',
        omitSessionKey: true
      });
      setLoading(false);
      return;
    }
    const url = new URL(`https://${subdomain}.bamboohr.com/authorize.php`);

    // set all the params that are required for sso
    url.searchParams.set('request', 'authorize');
    url.searchParams.set('state', 'bamboo_hr_state');
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('scope', 'openid email');
    url.searchParams.set('client_id', BAMBOOHR_CLIENT_ID.toString());
    url.searchParams.set('redirect_uri', BAMBOOHR_REDIRECT_URI);
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
    if (!externalWindowRef.current || externalWindowRef.current.closed) return;
    const closeWindow = () => {
      setLoading(false);
      externalWindowRef?.current?.close();
    };
    const checkWindowInterval = setInterval(async () => {
      if (externalWindowRef?.current?.closed) {
        setLoading(false);
        clearInterval(checkWindowInterval);
      } else {
        try {
          const urlParams = new URLSearchParams(externalWindowRef?.current?.location.search);
          const code = urlParams.get('code');
          const error = urlParams.get('error');
          if (code) {
            closeWindow();
            await onSubmit({
              ...fieldToValueMap,
              clientCode: code,
              providerRedirectUri: BAMBOOHR_REDIRECT_URI
            });
          }
          if (error) {
            setError({
              message: error
            });
            closeWindow();
          }
        } catch (e) {
          // ignore dom exception errors
          if (e instanceof DOMException) {
            return;
          }
          setError({
            message: JSON.stringify(e)
          });
          closeWindow();
        }
      }
    }, 500);
  };
  return <>
      <NoticeMessage data-sentry-element="NoticeMessage" data-sentry-source-file="RegisterBambooHRApi.tsx">
        BambooHR authentication will continue in a new window. Please make sure
        to allow popups and finish authenticating through BambooHR in the new
        window.
      </NoticeMessage>
      {sandboxMode === 'provider' && <InstructionHeader>
          Sign up for a BambooHR demo account{' '}
          <a href="https://bamboohr.com/signup" target="_blank" rel="noreferrer">
            on their website
          </a>{' '}
          and connect it here.
        </InstructionHeader>}
      <MultiValueForm onSubmit={handleSubmit} fieldNames={['subdomain']} render={({
      subdomain
    }) => <>
            <MainContainer>
              <Input id="subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} label="Subdomain (from <subdomain>.bamboohr.com)" autoFocus />
            </MainContainer>
            <LoaderButton isLoading={loading} disabled={loading} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterBambooHRApi.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Use a Username and Password
        </SwitchImplementationButton>}
    </>;
};
export default withAuthorizeContext(RegisterBambooHRApi);