import React, { useEffect, useRef, useState } from 'react';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import styled from 'styled-components';
import FONTS from 'constants/fonts';
import COLORS from 'constants/colors';
import { getBackendUrl } from 'services/get-backend-url';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
export const FACTORIAL_HR_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth/factorial_hr`;
export const FACTORIAL_HR_OAUTH_CALLBACK_KEY = 'factorial_hr_oauth_callback';
const StatusMessage = styled.p`
  margin-top: 8px;
  font-size: ${FONTS.P};
  color: ${COLORS.GRAY.GRAY_600};
  text-align: center;
`;
const BACKEND_ROUTE = getBackendUrl();
interface FactorialHRConfig {
  oauthUrl: string;
  clientId: string;
  scopes: string[];
}
type OAuthState = {
  code: string | null;
  state: string | null;
  error: string | null;
};
const RegisterFactorialHROAuth = ({
  client,
  provider,
  onSubmit,
  setError,
  sessionKey,
  onMockOAuth
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [loading, setLoading] = useState(false);
  const [polling, setPolling] = useState(false);
  const pollingTimer = useRef<NodeJS.Timeout>(); // Reference to timer so we can clean it up
  const externalWindowRef = useRef<Window | null>(null);
  const [statusMessage, setStatusMessage] = useState('');
  const config: FactorialHRConfig = (client as any).factorialHRApiConfig;
  useEffect(() => () => {
    if (pollingTimer.current) clearTimeout(pollingTimer.current);
  }, []);
  const onCodeReceived = async (oAuthState?: OAuthState) => {
    if (oAuthState?.error) {
      setError({
        message: oAuthState?.error
      });
      setLoading(false);
      setStatusMessage('');
      return;
    }
    setStatusMessage('Factorial HR authentication successful. Completing authorization...');
    if (oAuthState?.code) {
      try {
        await onSubmit({
          providerRedirectUri: FACTORIAL_HR_REDIRECT_URI,
          clientCode: oAuthState?.code
        });
      } finally {
        setLoading(false);
        setStatusMessage('');
      }
    }
  };
  useQuery({
    queryKey: ['factorial_hr_oauth_state', sessionKey],
    queryFn: () => {
      if (externalWindowRef?.current?.closed) {
        setLoading(false);
        setPolling(false);
        clearTimeout(pollingTimer.current);
      }
      return axios.get<{
        oAuthState?: OAuthState;
      }>(`${BACKEND_ROUTE}/auth/oauth/state/${sessionKey}`).then(({
        data
      }) => data);
    },
    enabled: polling,
    onSuccess: ({
      oAuthState
    }) => {
      (async () => {
        if (oAuthState) {
          setPolling(false);
          clearTimeout(pollingTimer.current);
          await onCodeReceived(oAuthState);
        }
      })();
    },
    // Poll OAuth state every second
    refetchInterval: 1000
  });
  const handleSubmit = async () => {
    const didMockOAuth = onMockOAuth();
    if (didMockOAuth) {
      return;
    }
    setLoading(true);
    setPolling(true);
    pollingTimer.current = setTimeout(() => {
      setLoading(false);
      setPolling(false);
      setStatusMessage('Your Factorial HR authentication has timed out. Please close the popup and try again.');
    }, 300_000); // Stop polling in 5 minutes

    const url = new URL(config.oauthUrl);
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('redirect_uri', FACTORIAL_HR_REDIRECT_URI);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('scope', config.scopes.join(' '));
    url.searchParams.set('state', sessionKey);
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
  };
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterFactorialHROAuth.tsx">
        Factorial HR authentication will continue in a new window. Please make
        sure to allow popups and finish authenticating through Factorial HR in
        the new window.
      </InstructionHeader>
      <MainContainer data-sentry-element="MainContainer" data-sentry-source-file="RegisterFactorialHROAuth.tsx" />
      <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} data-sentry-element="LoaderButton" data-sentry-source-file="RegisterFactorialHROAuth.tsx">
        Connect
      </LoaderButton>

      {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
    </>;
};
export default withAuthorizeContext(RegisterFactorialHROAuth);