import React, { useRef, useState } from 'react';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import MainContainer from 'components/Container/MainContainer';
import NoticeMessage from '../../Messages/Notice';
import { InstructionList } from '../../../../components/Instruction/Instructions';
const {
  protocol,
  host
} = window.location;
const SQUARE_REDIRECT_URI = `${protocol}//${host}/auth/square_payroll`;
const RegisterSquarePayrollOAuth = ({
  client,
  handleClick,
  onSubmit,
  provider,
  sandbox,
  setError,
  sandboxMode,
  onMockOAuth
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [loading, setLoading] = useState(false);
  const externalWindowRef = useRef<Window | null>(null);

  // use any for type until Finch common types can be updated
  const prodConfig = (client as any)?.squareApiConfig?.production;
  const sandboxConfig = (client as any)?.squareApiConfig?.sandbox;
  if (!prodConfig?.applicationId || !prodConfig?.baseUrl || !prodConfig?.scope) {
    throw new Error('Square Production API config is misconfigured');
  }
  if (!sandboxConfig?.applicationId || !sandboxConfig?.baseUrl || !sandboxConfig?.scope) {
    throw new Error('Square Sandbox API config is misconfigured');
  }

  // Get environment-specific configurations.
  const squareConfig = sandbox ? sandboxConfig : prodConfig;
  const squareApplicationId = squareConfig.applicationId;
  const squareApiUrl = squareConfig.baseUrl;
  const squareScope = squareConfig.scope;
  const closeWindow = () => {
    setLoading(false);
    externalWindowRef?.current?.close();
  };

  // Function to run when user initiates Square OAuth
  const handleSubmit = async () => {
    const didMockOAuth = onMockOAuth();
    if (didMockOAuth) {
      return;
    }
    setLoading(true);
    // Setup the request to initiate Square OAuth
    const url = new URL(`${squareApiUrl}/oauth2/authorize`);
    url.searchParams.set('client_id', squareApplicationId);
    url.searchParams.set('scope', squareScope.join(' '));
    url.searchParams.set('state', 'square_payroll_state');
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
    if (!externalWindowRef.current || externalWindowRef.current.closed) return;
    const checkWindowInterval = setInterval(async () => {
      if (externalWindowRef?.current?.closed) {
        clearInterval(checkWindowInterval);
      } else {
        try {
          const urlParams = new URLSearchParams(externalWindowRef?.current?.location.search);
          const code = urlParams.get('code');
          const error = urlParams.get('error');
          if (code) {
            closeWindow();
            await onSubmit({
              clientCode: code,
              providerRedirectUri: SQUARE_REDIRECT_URI
            });
          }
          if (error) {
            setError({
              message: error
            });
            closeWindow();
          }
        } catch (e) {
          // ignore dom exception errors
          if (e instanceof DOMException) {
            return;
          }
          setError({
            message: JSON.stringify(e)
          });
          closeWindow();
        }
      }
    }, 500);
  };
  const instructions: JSX.Element[] = [<>
      If you haven’t already,{' '}
      <a href="https://squareup.com/login?app=developer&lang_code=en-US&return_to=https://developer.squareup.com/apps?lang=en-US&country=us" target="_blank" rel="noreferrer">
        sign up for a free Square developer account.
      </a>
    </>, <>
      Log in to your developer account at{' '}
      <a href="https://developer.squareup.com/" target="_blank" rel="noreferrer">
        developer.squareup.com.
      </a>
    </>, <>
      In a separate tab, open the sandbox company you would like to connect to
      by clicking “Open” in the Square developer dashboard.
    </>, <>
      Click the “Connect” button below to initiate the OAuth flow for the
      company.
    </>];
  return <>
      <NoticeMessage data-sentry-element="NoticeMessage" data-sentry-source-file="RegisterSquarePayrollOAuth.tsx">
        <div>
          Square authentication will continue in a new window. Please make sure
          to allow popups and finish authenticating through Square in the new
          window.
        </div>
      </NoticeMessage>
      {sandboxMode === 'provider' && <InstructionList instructions={instructions} color={provider.primaryColor} />}
      <MainContainer data-sentry-element="MainContainer" data-sentry-source-file="RegisterSquarePayrollOAuth.tsx" />
      <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} data-sentry-element="LoaderButton" data-sentry-source-file="RegisterSquarePayrollOAuth.tsx">
        Connect
      </LoaderButton>
      {!loading && handleClick && <SwitchImplementationButton onClick={handleClick}>
          Use a Username and Password
        </SwitchImplementationButton>}
    </>;
};
export default withAuthorizeContext(RegisterSquarePayrollOAuth);