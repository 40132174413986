import React, { useRef, useState } from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import LoaderButton from 'components/Button/LoaderButton';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
const {
  protocol,
  host
} = window.location;
const SQUARE_REDIRECT_URI = `${protocol}//${host}/auth/square_payroll`;
const SignInSquareOAuth = ({
  client,
  sandbox,
  onSubmit,
  onMockOAuth,
  setError,
  handleClick
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const instructions: JSX.Element = <p>
      Square authentication will continue in a new window. Please make sure to
      allow popups and finish authenticating through Square in the new window.
    </p>;
  const [loading, setLoading] = useState(false);
  const externalWindowRef = useRef<Window | null>(null);

  // use any for type until Finch common types can be updated
  const prodConfig = (client as any)?.squareApiConfig?.production;
  const sandboxConfig = (client as any)?.squareApiConfig?.sandbox;
  if (!prodConfig?.applicationId || !prodConfig?.baseUrl || !prodConfig?.scope) {
    throw new Error('Square Production API config is misconfigured');
  }
  if (!sandboxConfig?.applicationId || !sandboxConfig?.baseUrl || !sandboxConfig?.scope) {
    throw new Error('Square Sandbox API config is misconfigured');
  }

  // Get environment-specific configurations.
  const squareConfig = sandbox ? sandboxConfig : prodConfig;
  const squareApplicationId = squareConfig.applicationId;
  const squareApiUrl = squareConfig.baseUrl;
  const squareScope = squareConfig.scope;
  const closeWindow = () => {
    setLoading(false);
    externalWindowRef?.current?.close();
  };

  // Function to run when user initiates Square OAuth
  const handleSubmit = async () => {
    const didMockOAuth = onMockOAuth();
    if (didMockOAuth) {
      return;
    }
    setLoading(true);
    // Setup the request to initiate Square OAuth
    const url = new URL(`${squareApiUrl}/oauth2/authorize`);
    url.searchParams.set('client_id', squareApplicationId);
    url.searchParams.set('scope', squareScope.join(' '));
    url.searchParams.set('state', 'square_payroll_state');
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
    if (!externalWindowRef.current || externalWindowRef.current.closed) return;
    const checkWindowInterval = setInterval(async () => {
      if (externalWindowRef?.current?.closed) {
        clearInterval(checkWindowInterval);
      } else {
        try {
          const urlParams = new URLSearchParams(externalWindowRef?.current?.location.search);
          const code = urlParams.get('code');
          const error = urlParams.get('error');
          if (code) {
            closeWindow();
            await onSubmit({
              clientCode: code,
              providerRedirectUri: SQUARE_REDIRECT_URI
            });
          }
          if (error) {
            setError({
              message: error
            });
            closeWindow();
          }
        } catch (e) {
          // ignore dom exception errors
          if (e instanceof DOMException) {
            return;
          }
          setError({
            message: JSON.stringify(e)
          });
          closeWindow();
        }
      }
    }, 500);
  };
  return <SignInBase instructions={instructions} data-sentry-element="SignInBase" data-sentry-component="SignInSquareOAuth" data-sentry-source-file="SignInSquareOAuth.tsx">
      <div style={{
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
        {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
            Use an Email Address and Password instead
          </SwitchImplementationButton>}
        <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} data-sentry-element="LoaderButton" data-sentry-source-file="SignInSquareOAuth.tsx">
          Connect
        </LoaderButton>
      </div>
    </SignInBase>;
};
export default withAuthorizeContext(SignInSquareOAuth);