// Design system from https://www.figma.com/file/gldI3ap3tphioNftkEHThx/Employers?type=design&node-id=901-450&t=9EIQxDJnnhyp6LFR-0
const GRAY = {
  GRAY_200: '#f4f4f4',
  GRAY_400: '#d9d9d9',
  GRAY_600: '#737373',
  GRAY_900: '#161616',
};

const RED = {
  RED_100: '#fee4e2',
  RED_300: '#fda29b',
  RED_500: '#f04438',
  RED_600: '#b42318',
};

const FINCH = {
  BLACK: '#161616',
  PURPLE: '#3E4AE7',
  LAVENDER: '#CAD0EB',
};

const COLORS = {
  BLACK: '#161616',
  WHITE: '#ffffff',
  GRAY,
  RED,
  FINCH,
};

export default COLORS;
