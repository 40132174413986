import React from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { SubdomainInput } from 'components/Input/SubdomainInput';
import { apiTokenInstructions } from '../utils';
const SignInPersonioApi = ({
  provider,
  client,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  // TODO: add link
  const instructions = apiTokenInstructions(provider.displayName, client.name);
  return <SignInBase instructions={instructions} divider onSubmit={onSubmit} fieldNames={['instructionCheck', 'subdomain', 'providerClientId', 'providerClientSecret']} render={({
    instructionCheck,
    subdomain,
    providerClientId,
    providerClientSecret
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <SubdomainInput label="Subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} domain="personio.de" />
              <Input id="providerClientId" value={providerClientId.value} onChange={event => providerClientId.onChange(event.target.value)} invalid={providerClientId.invalid} label="Client Id" />
              <Input id="providerClientSecret" value={providerClientSecret.value} onChange={event => providerClientSecret.onChange(event.target.value)} invalid={providerClientSecret.invalid} label="Client Secret" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !subdomain.value || !providerClientId.value || !providerClientSecret.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInPersonioApi" data-sentry-source-file="SignInPersonioApi.tsx" />;
};
export default SignInPersonioApi;