import { datadogRum } from '@datadog/browser-rum';
import { useUnleashContext } from '@unleash/proxy-client-react';
import * as Sentry from '@sentry/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Category, Sandbox } from '@finch-api/common/dist/internal/connect/authorize';
import closeXIcon from 'assets/img/x.svg';
import Notifications from 'components/Notifications/Notifications';
import { Clients } from 'constants/clients';
import { paramAppType, paramCategory, paramClientId, paramClientName, paramConnectionId, paramManual, paramPayrollProvider, paramProducts, paramRedirectUri, paramSandbox, paramSdkVersion, paramSessionId, paramState } from 'constants/params';
import { FINCH_DOCS_BASE_URL } from 'constants/urls';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { step, stepMap } from 'pages/Authorize/steps';
import { validate, validateAppType, validateCategory, validateSessionId } from 'services/auth';
import { AnalyticsEventName, identifySession, track } from 'utils/analytics';
import styles from './Authorize.module.scss';
import CanOnlyUseSandbox from './Errors/CanOnlyUseSandbox';
import InvalidSandboxApplication from './Errors/InvalidSandboxApplication';
import SandboxAccessDenied from './Errors/SandboxAccessDenied';
import ProviderSandboxConnectionLimit from './Errors/ProviderSandboxConnectionLimit';
import { query } from 'utils/params';
import { CanNotUseAssistedInSandbox } from './Errors/CanNotUseAssisted';
import FinchSandboxConnectionLimit from './Errors/FinchSandboxConnectionLimit';
import { useStagedConfigStore } from './useStagedConfig';
import { EMPLOYER_PREFIX } from 'constants/products';
import GenericError from './Errors/GenericError';
import { Session } from '@finch-api/common/dist/internal/connect/validate';
const TopBar = ({
  text
}: {
  text: React.ReactNode;
}) => <div className={styles.testingBar} data-sentry-component="TopBar" data-sentry-source-file="Authorize.tsx">{text}</div>;
export const NO_REFERRER_URL = 'no-referrer-found';
const SESSION_ERRORS = ['Session not found', 'Session expired', 'Session link already used'];
const sessionErrorMap = {
  'Session not found': 'This session link is invalid',
  'Session expired': 'This session link has expired',
  'Session link already used': 'This session link has already been used to get connected. Feel free to close this window'
};
export const withSandboxURL = () => {
  const url = new URL(window.location.href);
  const qp = url.searchParams;
  qp.set(paramSandbox, 'true');
  url.search = qp.toString();
  return url.toString();
};
const parseSandbox = (sandbox: string): Sandbox => {
  switch (sandbox) {
    case 'finch':
    case 'provider':
      return sandbox;
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
    default:
      return false;
  }
};
const AuthorizeContent = ({
  currentStep,
  shortSessionKey,
  showCanOnlyUseSandboxScreen,
  showInvalidSandboxAppScreen,
  showSandboxAccessDeniedScreen,
  showProviderSandboxLimitScreen,
  showFinchSandboxLimitScreen,
  cannotUseAssistedForSandbox,
  sandboxMode,
  sessionError
}: {
  currentStep: string;
  shortSessionKey?: string;
  showCanOnlyUseSandboxScreen?: boolean;
  showInvalidSandboxAppScreen?: boolean;
  showSandboxAccessDeniedScreen?: boolean;
  showProviderSandboxLimitScreen: boolean;
  showFinchSandboxLimitScreen: boolean;
  cannotUseAssistedForSandbox?: boolean;
  sandboxMode?: Sandbox;
  sessionError?: keyof typeof sessionErrorMap;
}) => {
  if (sessionError) {
    return <GenericError title="There was an error with this session" errorCode={shortSessionKey}>
        {sessionErrorMap[sessionError]}
      </GenericError>;
  }
  if (cannotUseAssistedForSandbox && sandboxMode) {
    return <CanNotUseAssistedInSandbox mode={sandboxMode} />;
  }
  if (showSandboxAccessDeniedScreen) return <SandboxAccessDenied />;
  if (showInvalidSandboxAppScreen) {
    return <InvalidSandboxApplication shortSessionKey={shortSessionKey} />;
  }
  if (showProviderSandboxLimitScreen) {
    return <ProviderSandboxConnectionLimit shortSessionKey={shortSessionKey} />;
  }
  if (showFinchSandboxLimitScreen) {
    return <FinchSandboxConnectionLimit shortSessionKey={shortSessionKey} />;
  }
  if (showCanOnlyUseSandboxScreen) {
    return <CanOnlyUseSandbox />;
  }
  return stepMap[currentStep]();
};
const Authorize = ({
  location,
  currentStep,
  clientId,
  setClientId,
  setConnectionId,
  setConnection,
  setClientName,
  setRedirectUri,
  setProductsWrapper,
  setClient,
  setState,
  setAppType,
  setCategory,
  setCurrentStep,
  setPayrollProvider,
  setProviderImplementation,
  setProvidersConfig,
  manual,
  setManual,
  setSandbox,
  setSdkVersion,
  completeAuth,
  appType,
  client,
  sessionKey,
  setSessionKey,
  sandbox,
  error,
  setError,
  shortSessionKey,
  providerToRedirect,
  setProviderToRedirect,
  handleStateRedirect,
  skipParamValidation = false,
  preSetImplementationKind,
  setPreSetImplementationKind,
  setIdpRedirect
}: {
  location: {
    search: string;
  };
  skipParamValidation: boolean;
} & AuthorizeContextValue) => {
  const history = useHistory();
  const [sessionError, setSessionError] = useState<keyof typeof sessionErrorMap>();

  /**
   * Redirecting within the iFramed Connect opened by the SDK changes the document.referrer to the Connect URL,
   * so we need to capture the original referrer URL in case a redirect happens. The document.referrer is used to send
   * postMessages back to the SDK.
   */
  if (query('app_type').toLowerCase().trim() === 'spa' && !query('sdk_host_url')) {
    const sdkHostUrl = document?.referrer || NO_REFERRER_URL;
    Sentry.addBreadcrumb({
      category: 'sdk-events',
      message: `Reloading URL with the sdk_host_url ${sdkHostUrl}`,
      data: {
        sdkHostUrl
      },
      level: 'info'
    });
    history.replace({
      pathname: '/authorize',
      search: `${location.search}&sdk_host_url=${sdkHostUrl}`
    });
  }
  const setSession = (session: Session) => {
    setClientId(session.clientId);
    setRedirectUri(session.redirectUri);
    setSandbox(session.sandbox);
    setManual(session.manual ?? false);
    setClientId(session.clientId);
    const products = setProductsWrapper(session.products.map(product => product.replace(EMPLOYER_PREFIX, '')) || []);
    setPreSetImplementationKind(session.implementationKind);
    setIdpRedirect(session.idp);
    setClientName(session.clientName);
    if (session.connectionId) {
      setConnectionId(session.connectionId);
    }
    if (session.connection) {
      setConnection(session.connection);
    }
    return {
      products
    };
  };
  const stagedConfig = useStagedConfigStore(state => state.stagedConfig);
  const updateUnleashContext = useUnleashContext();
  const [showProviderSandboxLimit, setShowProviderSandboxLimitScreen] = useState(false);
  const [showFinchSandboxLimit, setShowFinchSandboxLimitScreen] = useState(false);
  const [showSandboxAccessDeniedScreen, setShowSandboxAccessDeniedScreen] = useState(false);
  const [showCanOnlyUseSandboxScreen, setShowCanOnlyUseSandboxScreen] = useState(false);
  const [showInvalidSandboxAppScreen, setShowInvalidSandboxAppScreen] = useState(false);
  useEffect(() => {
    track(AnalyticsEventName.ViewedConnectStep, {
      step: currentStep
    });
  }, [currentStep]);
  useEffect(() => {
    /* Datadog RUM has a quirk where actions that happen when a new view is registered are ignored.
    The timeout is to ensure we capture click actions on the previous view before the new view is registered */
    setTimeout(() => {
      datadogRum.startView({
        name: currentStep
      });
    }, 500);
  }, [currentStep]);
  useEffect(() => {
    const paramValidation = async () => {
      const sessionId = query(paramSessionId);
      const clientId = query(paramClientId);
      const connectionId = query(paramConnectionId) || undefined;
      const redirectUri = query(paramRedirectUri);
      const rawProducts = query(paramProducts);
      const state = query(paramState) || undefined;
      const appType = query(paramAppType) as 'ssr' | 'spa';
      const category = (query(paramCategory) || Category.HRIS) as Category;
      const payrollProviderId = query(paramPayrollProvider);
      const manualQuery = query(paramManual);
      const sandbox = query(paramSandbox);
      const clientName = query(paramClientName) || undefined;
      // TODO not used currently. Will be used for monitoring
      const sdkVersion = query(paramSdkVersion);
      try {
        if (category) {
          validateCategory(category);
          setCategory(category);
        }
        if (appType) {
          validateAppType(appType);
          setAppType(appType);
        }
        const sandboxValue = parseSandbox(sandbox);
        setState(state);
        setSdkVersion(sdkVersion);
        updateUnleashContext({
          userId: sessionKey,
          properties: {
            clientId: clientId || ''
          }
        });
        if (sessionId) {
          setSessionKey(sessionId);
        }
        if (clientName) {
          setClientName(clientName);
        }
        const client = sessionId ? await validateSessionId({
          sessionId,
          redirectUri: redirectUri.trim() || undefined,
          products: rawProducts ? rawProducts.split(' ').map(product => product.trim()).filter(Boolean) : undefined
        }) : await validate({
          clientId,
          redirectUri,
          connectionId,
          products: rawProducts.split(' '),
          category,
          sessionKey,
          manual: manualQuery === 'true' || manualQuery === '1',
          sandbox: sandboxValue,
          providerId: payrollProviderId,
          clientName
        });
        setSession(client.session);
        identifySession({
          sessionKey,
          clientId,
          session: client.session,
          clientName: client.name
        });
        datadogRum.setGlobalContext({
          scopes: rawProducts,
          clientId,
          redirectUri,
          state,
          appType,
          category,
          payrollProviderId,
          manual: manualQuery,
          sandbox,
          sdkVersion,
          applicationName: client?.name
        });
        Sentry.setTags({
          application_id: clientId,
          application_name: client.name,
          provider_id: payrollProviderId
        });
        Sentry.setContext('authorize', {
          scopes: rawProducts,
          clientId,
          redirectUri,
          state,
          appType,
          category,
          payrollProviderId,
          manual: manualQuery,
          sandbox,
          sdkVersion,
          applicationName: client?.name
        });
        setClient(client);
        setProvidersConfig(client.providersConfig);

        // this part should be removed after auth config rollout
        // we should check the length of the providerConfig
        // and pass select screen if return configs has only length = 1
        // this should be true for applications that only have 1 provider in config
        if (payrollProviderId) {
          setPayrollProvider(client.providersConfig[0]);
        }
        const selectedProvider = client.providersConfig.find(provider => provider.id === payrollProviderId);
        const selectedImplementation = selectedProvider?.implementations.find(implementation => implementation.kind === preSetImplementationKind);
        setProviderImplementation(selectedImplementation);
        if (client.session.connectionId) {
          const payrollProvider = client.providersConfig[0];
          setPayrollProvider(payrollProvider);
          setProviderImplementation(payrollProvider?.implementations[0]);
        }

        // Go directly to provider selection if the skipConnectAgreement flag is set
        if (client?.settings?.skipConnectAgreement) {
          setCurrentStep(step.SELECT_PROVIDER);
        }
      } catch (err) {
        if (err instanceof Error) {
          if (SESSION_ERRORS.includes(err.message)) {
            setSessionError(err.message as keyof typeof sessionErrorMap);
          } else if (err.message === 'Invalid Client For Provider Sandbox') {
            setShowInvalidSandboxAppScreen(true);
          } else if (err.message === 'Provider Sandbox Connection Limit Reached') {
            setShowProviderSandboxLimitScreen(true);
          } else if (err.message === 'Finch Sandbox Connection Limit Reached') {
            setShowFinchSandboxLimitScreen(true);
          } else if (err.message === 'Finch Sandbox is not available') {
            setShowSandboxAccessDeniedScreen(true);
          } else if (err.message === 'Client only has access to the sandbox mode') {
            setShowCanOnlyUseSandboxScreen(true);
          } else if (appType === 'spa') {
            completeAuth({
              error: err.message,
              errorType: 'validation_error',
              forceSpa: true
            });
          } else {
            setError({
              message: err.message
            });
          }
        }
      }
    };
    if (!skipParamValidation) {
      paramValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClose = () => {
    if (appType === 'spa') {
      completeAuth({
        closed: true
      });
    }
  };
  const sandboxDocumentation = (modeName: Exclude<Sandbox, boolean>) => {
    const capitalizedMode = modeName.charAt(0).toUpperCase() + modeName.slice(1);
    const urlSuffix = modeName === 'finch' ? 'Finch-Sandbox#simulating-credential-flows' : 'Provider-Test-Environments';
    return <span data-sentry-component="sandboxDocumentation" data-sentry-source-file="Authorize.tsx">
        You are currently in {capitalizedMode} Sandbox Mode. Visit our{' '}
        <a href={`${FINCH_DOCS_BASE_URL}/implementation-guide/Test/${urlSuffix}`} target="_blank" rel="noreferrer" onClick={() => track(AnalyticsEventName.ClickedSandboxInstructions)}>
          sandbox documentation
        </a>{' '}
        for the login/password and more information.
      </span>;
  };
  const sandboxText = useMemo(() => {
    switch (sandbox) {
      case true:
        return sandboxDocumentation('finch');
      case 'finch':
      case 'provider':
        return sandboxDocumentation(sandbox);
      default:
        return <span>
            Your application can only be used in Sandbox mode.{' '}
            <a href={withSandboxURL()}>Connect</a> your application to a sandbox
            provider!
          </span>;
    }
  }, [sandbox]);
  return <div className={styles.outerContainer} onClick={onClose} data-sentry-component="Authorize" data-sentry-source-file="Authorize.tsx">
      {/* This is the Finch Homepage client, API Server doesn't actually invoke
       the Adapter with these credentials, instead it replaces them and mocks a
       response. ie you can choose Gusto, sign in with bogus email/password and
       it won't ever send these to Gusto! */}
      {clientId === Clients.Finch.Homepage && <TopBar text="You are currently in Demo mode. Enter any credentials to log in." />}
      {client?.status === 'SANDBOX' && <TopBar text={sandboxText} />}
      {stagedConfig && <TopBar text="You are currently in Preview mode" />}

      <div className={styles.container} onClick={e => {
      e.stopPropagation();
    }}>
        <Notifications error={error} shortSessionKey={shortSessionKey} providerToRedirect={providerToRedirect} setProviderToRedirect={setProviderToRedirect} handleStateRedirect={handleStateRedirect} setError={setError} data-sentry-element="Notifications" data-sentry-source-file="Authorize.tsx" />
        {appType === 'spa' && <img src={closeXIcon} className={styles.closeIcon} onClick={onClose} alt="Close" aria-label="Close" />}

        <AuthorizeContent currentStep={currentStep} shortSessionKey={shortSessionKey} showCanOnlyUseSandboxScreen={showCanOnlyUseSandboxScreen} showInvalidSandboxAppScreen={showInvalidSandboxAppScreen} showSandboxAccessDeniedScreen={showSandboxAccessDeniedScreen} showProviderSandboxLimitScreen={showProviderSandboxLimit} showFinchSandboxLimitScreen={showFinchSandboxLimit} cannotUseAssistedForSandbox={sandbox === 'provider' && manual === true} sandboxMode={sandbox} sessionError={sessionError} data-sentry-element="AuthorizeContent" data-sentry-source-file="Authorize.tsx" />
      </div>
    </div>;
};
export default withAuthorizeContext(Authorize);