import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { getBackendUrl } from '../../../../services/get-backend-url';
import FONTS from '../../../../constants/fonts';
import COLORS from '../../../../constants/colors';
import NoticeMessage from '../../Messages/Notice';
export const DEEL_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth/deel`;
export const DEEL_OAUTH_CALLBACK_KEY = 'deel_oauth_callback';
const StatusMessage = styled.p`
  margin-top: 8px;
  font-size: ${FONTS.P};
  color: ${COLORS.GRAY.GRAY_600};
  text-align: center;
`;
const BACKEND_ROUTE = getBackendUrl();
interface DeelConfig {
  oauthUrl: string;
  clientId: string;
  scopes: string[];
  apimSubscriptionKey: string;
  signInUrl: string;
}
type OAuthState = {
  code: string | null;
  state: string | null;
  error: string | null;
};
const RegisterDeelOAuth = ({
  client,
  provider,
  sandbox,
  onSubmit,
  setError,
  sessionKey,
  onMockOAuth
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [loading, setLoading] = useState(false);
  const [polling, setPolling] = useState(false);
  const pollingTimer = useRef<NodeJS.Timeout>(); // Reference to timer so we can clean it up
  const [statusMessage, setStatusMessage] = useState('');
  const prodConfig: DeelConfig = (client as any).deelApiConfig.production;
  const sandboxConfig: DeelConfig = (client as any).deelApiConfig.sandbox;

  // Cleanup timer on unmount
  useEffect(() => () => {
    if (pollingTimer.current) clearTimeout(pollingTimer.current!);
  }, []);
  const onCodeReceived = async (oAuthState?: OAuthState) => {
    if (oAuthState?.error) {
      setError({
        message: oAuthState?.error
      });
      setLoading(false);
      setStatusMessage('');
      return;
    }
    setStatusMessage('Deel authentication successful. Completing authorization...');
    if (oAuthState?.code) {
      try {
        await onSubmit({
          providerRedirectUri: DEEL_REDIRECT_URI,
          clientCode: oAuthState?.code
        });
      } finally {
        setLoading(false);
        setStatusMessage('');
      }
    }
  };
  const config = sandbox ? sandboxConfig : prodConfig;
  useQuery({
    queryKey: ['deel_oauth_state', sessionKey],
    queryFn: () => axios.get<{
      oAuthState?: OAuthState;
    }>(`${BACKEND_ROUTE}/auth/oauth/state/${sessionKey}`).then(({
      data
    }) => data),
    enabled: polling,
    onSuccess: async ({
      oAuthState
    }) => {
      if (oAuthState) {
        setPolling(false);
        clearTimeout(pollingTimer.current!);
        await onCodeReceived(oAuthState);
      }
    },
    // Poll OAuth state every second
    refetchInterval: 1000
  });
  const handleSubmit = async () => {
    const didMockOAuth = onMockOAuth();
    if (didMockOAuth) {
      return;
    }
    setLoading(true);
    setPolling(true);
    pollingTimer.current = setTimeout(() => {
      setLoading(false);
      setPolling(false);
      setStatusMessage('Your Deel authentication has timed out. Please close the popup and try again.');
    }, 300_000); // Stop polling in 5 minutes

    const url = new URL(config.oauthUrl);
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('scope', config.scopes.join(' '));
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('redirect_uri', DEEL_REDIRECT_URI);
    url.searchParams.set('state', JSON.stringify({
      sessionKey
    }));
    window.open(url.toString(), 'popup', 'popup=true');
  };
  return <>
      <NoticeMessage data-sentry-element="NoticeMessage" data-sentry-source-file="RegisterDeelOAuth.tsx">
        Deel authentication will continue in a popup. Please make sure to finish
        authenticating through Deel in the new window.
      </NoticeMessage>
      {sandbox === 'provider' && <InstructionHeader>
          <a href="https://developer.tryfinch.com/docs/documentation/pzie4zvmok31z-provider-sandboxes#deel" target="_blank" rel="noreferrer">
            Follow the setup instructions
          </a>{' '}
          to create a demo Deel company. Once you have a demo company set up,
          connect it here.
        </InstructionHeader>}

      <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} data-sentry-element="LoaderButton" data-sentry-source-file="RegisterDeelOAuth.tsx">
        Connect
      </LoaderButton>

      {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
    </>;
};
export default withAuthorizeContext(RegisterDeelOAuth);